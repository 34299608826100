import React from 'react';
import Layout from '@/components/layout';
import Icon from 'supercons';
import Tags from '@/components/tags';
import { graphql, Link } from 'gatsby';
import { DateTime } from 'luxon';
import { formatDate } from '@/model/format-date';
// @ts-ignore
import yall from 'yall-js';
import 'swiper/swiper-bundle.css';

interface QueryProps {
  markdownRemark: {
    frontmatter: {
      author: string;
      publish_date: string;
      tags: string[];
      title?: string;
    };
    fields: {
      slug: string;
    };
    html: string;
  };
  previous: RelatedPost;
  next: RelatedPost;
}

interface RelatedPost {
  fields: {
    slug: string;
  };
  frontmatter: {
    publish_date: string;
  };
}

DateTime.local().setZone('America/Los Angeles');

export default function Post({ data }: { data: QueryProps }) {
  if (typeof window !== undefined && typeof document !== 'undefined') {
    yall({
      observeChanges: true,
      events: {
        load: ({ target }: { target: HTMLElement }) => {
          if (!target.classList.contains('lazy') && target.nodeName == 'IMG') {
            setTimeout(() => target.classList.add('loaded'), 1000);
          }
        },
      }
    });


    const { default: Swiper, Mousewheel } = require('swiper');
    Swiper.use([Mousewheel]);

    let swiper;

    setTimeout(() => {
      swiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        centerInsufficientSlides: true,
        spaceBetween: 30,
        freeMode: true,
        grabCursor: true,
        mousewheel: {
          forceToAxis: true,
        },
      });
    });
  }

  const post = data.markdownRemark;
  const { previous, next } = data;
  const title = post.frontmatter.title || formatDate(post.frontmatter.publish_date);

  return (
    <Layout>
      <article
        className="py-24 mx-auto"
        itemID="#"
        itemScope
        itemType="http://schema.org/BlogPosting">
        <div className="w-full mx-auto mb-12 text-center md:w-2/3">
          <Link
            to={`/posts`}
            className="mb-4 block text-clay text-xs tracking-wider font-title uppercase">
            Farm Journal
          </Link>

          <h1
            className="mb-3 text-4xl font-bold md:leading-tight md:text-5xl"
            itemProp="headline"
            title={title}>
            {title}
          </h1>

          <div className="flex py-2 justify-center space-x-4">
            <Tags tags={post.frontmatter.tags} />
          </div>
        </div>

        <div className="mx-auto prose mb-4 max-w-none">
          <p
            className="tracking-wide font-semibold"
            itemProp="author"
            itemType="http://schema.org/Person">
            <span className="text-base" itemProp="name">By {post.frontmatter.author}</span>
          </p>
        </div>

        <div
          className="mx-auto prose max-w-none"
          dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>

      <div className="p-12 mb-12 flex justify-between font-title relative z-30">
        <div>
          {previous && (
            <Link
              className="flex items-center"
              to={`/posts${previous.fields.slug}`}
              rel="prev">
              <Icon
                className="mr-4"
                glyph="back"
                size={32} />

              <span className="link-underline border-charcoal hidden md:inline-block">
                {formatDate(previous.frontmatter.publish_date)}
              </span>
            </Link>
          )}
        </div>
        <div>
          {next && (
            <Link
              className="flex items-center"
              to={`/posts${next.fields.slug}`}
              rel="next">
              <span className="link-underline border-charcoal hidden md:inline-block">
                {formatDate(next.frontmatter.publish_date)}
              </span>

              <Icon
                className="ml-4"
                glyph="enter"
                size={32} />
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
}

// Dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query(
    $slug: String!
    $previous: String
    $next: String
  ) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        author
        publish_date
        tags
        title
      }
      html
    }
    previous: markdownRemark(fields: { slug: { eq: $previous } }) {
      fields {
        slug
      }
      frontmatter {
        publish_date
      }
    }
    next: markdownRemark(fields: { slug: { eq: $next } }) {
      fields {
        slug
      }
      frontmatter {
        publish_date
      }
    }
  }
`
